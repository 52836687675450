<template>
  <div
    class="accordion__item"
    itemscope
    itemprop="mainEntity"
    itemtype="https://schema.org/Question"
  >
    <div class="accordion__header" @click="toggleItem">
      <h3
        class="accordion__title"
        :class="show ? 'accordion__title--open' : ''"
        itemprop="name"
      >
        <slot name="title">Título default para el accordion Header</slot>
      </h3>
      <div class="accordion__arrow" :class="show ? 'accordion__arrow--open' : ''">
        <i class="uil uil-angle-down"></i>
      </div>
    </div>
    <div
      v-show="show"
      class="accordion__content"
      itemscope
      itemprop="acceptedAnswer"
      itemtype="https://schema.org/Answer"
    >
      <div itemprop="text" class="post-entry">
        <slot name="content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed eros turpis.
          Donec auctor consectetur ultrices. Sed in lacus vel nunc tristique iaculis.
          Nullam dapibus sagittis est quis suscipit. Suspendisse potenti. Quisque libero
          sem, aliquet sit amet tellus eget, vehicula mollis mi. Cras pretium quis justo
          nec pellentesque. Duis sit amet erat ullamcorper, auctor risus ut, porta lectus.
          Nunc tempor massa est, ac vulputate urna dignissim id. Mauris porta mauris
          finibus arcu mattis tempus. Vivamus nec aliquam diam.
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "accordionItem",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleItem() {
      this.show = !this.show;
    },
  },
};
</script>
