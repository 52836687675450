<template>
  <accordionItem>
    <template v-slot:title>
      {{ title }}
    </template>
    <template v-slot:content>
      <div v-html="htmlContent" />
    </template>
  </accordionItem>
</template>

<script>
import accordionItem from "./accordionItem";

export default {
  props: {
    i18nPrefix: String,
    i18nItem: String,
  },
  components: {
    accordionItem,
  },
  data() {
    return {
      title: "",
      htmlContent: "",
    };
  },
  created() {
    this.title = this.$t(`${this.i18nPrefix}.items.${this.i18nItem}.title`);
    this.htmlContent = this.$t(`${this.i18nPrefix}.items.${this.i18nItem}.htmlContent`);
  },
};
</script>
