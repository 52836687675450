<template>
  <div :id="anchor || i18nPrefix" class="faq-list__nav">
    <header class="faq-list__header">
      <i v-if="ico" :class="ico"></i>
      <img v-else-if="icoSvg" :src="icoSvg" :alt="$t(i18nPrefix + '.header')" />
      <img
        v-else
        src="../../../assets/img/logos/logo-hellobb-isotip.svg"
        alt="Isotip HelloBB"
      />

      {{ $t(i18nPrefix + ".header") }}
    </header>
    <div class="accordion">
      <FaqAccordionItem
        v-for="(item, index) in items"
        :key="item.key"
        :i18nPrefix="i18nPrefix"
        :i18nItem="item.key"
      />
    </div>
  </div>
</template>

<script>
import FaqAccordionItem from "./FaqAccordionItem.vue";

export default {
  name: "FaqSection",
  components: {
    FaqAccordionItem,
  },
  props: {
    i18nPrefix: String,
    anchor: {
      type: String,
      default: "",
    },
    ico: {
      type: String,
      default: "",
    },
    icoSvg: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: [],
    },
  },
};
</script>
