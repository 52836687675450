<template>
  <div class="cta-help">
    <h3 class="cta-help__h">{{ $t("faq.noResponseFound") }}</h3>
    <div class="cta-help__actions" v-html="$t('faq.noResponseFoundCta')">
      
    </div>
  </div>
</template>
<script>
export default {
  name: "CtaFaqHelp",
};
</script>
